<template>
  <div class="container">
      <v-row class="">
      <v-col cols="12" class="top-panel">
        <div class="arrow-btn">
          <img
            src="../assets/img/arrowBack.svg"
            class="img-pluss-btn"
            alt="arrow-back"
          />
        </div>
        <div class="vertical-line-1"></div>
        <div class="add-btn" @click="createItem">
          <img src="../assets/img/plusAdd.svg" alt="pluss" />
          <p class="add-text">Добавить</p>
        </div>
        <div class="vertical-line"></div>
      </v-col>
    </v-row>
    <div id="accounts">
      <v-container class="secondary mt-10">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="filteredAccounts"
              :search="search"
              :footer-props="footerProps"
              hide-default-footer
            >
            <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="page-title"
                    >Юридические Лица
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <div class="search-component">
                    <input
                      type="search"
                      class="search-component__input mr-10"
                      v-model="search"
                      append-icon="mdi-magnify"
                      placeholder="Поиск"
                      single-line
                      hide-details
                    />
                    <svg width="17" height="17" class="search-component__icon">
                      <use href="../assets/icons/icons.svg#search-icon"></use>
                    </svg>
                  </div>
                  <!-- <v-text-field
                    class="mr-10"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Поиск"
                    single-line
                    hide-details
                  ></v-text-field> -->
                  <!-- <button id="staff-add-button" @click="createItem">
                    Создать новый ресторан +
                  </button> -->
                  <v-dialog v-model="dialogCreate" max-width="537px">
                    <v-card>
                      <v-card-title class="modal-title">
                        Юридические лица
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-col cols="12" class="py-0 px-0">
                          <div class="col-content px-0 pt-3 pb-0">
                            <v-col cols="3" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Имя</p>
                            </v-col>
                            <v-col cols="1" class="py-0 pl-1 red-dot-margin-1">
                              <span class="red-dot">*</span>
                            </v-col>
                            <v-col cols="8" class="col-paddings-r-l px-0 py-0">
                              <textarea
                                class="col-description"
                                v-model="fullName"
                                ref="fullNameTextarea"
                                :placeholder="
                                  isfullNameFocused ? '' : textareaPlaceholder
                                "
                                @focus="isfullNameFocused = true"
                                @blur="isfullNameFocused = false"
                              ></textarea>
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>
                        <v-col cols="12" class="py-0 px-0">
                          <div class="col-content px-0 pt-3 pb-0">
                            <v-col cols="3" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Бренд</p>
                            </v-col>
                            <v-col cols="1" class="py-0 pl-1 red-dot-margin-1">
                              <span class="red-dot">*</span>
                            </v-col>
                            <v-col cols="8" class="col-paddings-r-l px-0 py-0">
                              <textarea
                                class="col-description"
                                v-model="brandName"
                                ref="brandNameTextarea"
                                :placeholder="
                                  isBrandNameFocused ? '' : textareaPlaceholder
                                "
                                @focus="isBrandNameFocused = true"
                                @blur="isBrandNameFocused = false"
                              ></textarea>
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>
                      </v-card-text>
                      <div class="btn-container">
                        <div class="cancel-btn" text @click="closeCreate">
                          Отмена
                        </div>

                        <div class="save-btn" text @click="handleButtonClick">
                          Сохранить
                        </div>
                      </div>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="660px">
                    <v-card>
                      <v-card-title class="text-h5"
                        >Вы точно хотите удалить данное юридическое лицо?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <div class="cancel-btn" text @click="closeDelete">
                          Отмена
                        </div>
                        <div class="del-btn" text @click="deleteItemConfirm">
                          Удалить
                        </div>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
                <v-snackbar v-model="snackbar" :timeout="3000" class="btn-contaiener">
                  {{ message }}
                  <template v-slot:action="{ attrs }">
                    <div class="snackbar-btn" text v-bind="attrs" @click="snackbar = false">
                          Закрыть
                        </div>
                </template>
            </v-snackbar>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td @click="getAccount(item)">
                    {{ item.name || " " }}
                  </td>
                  <td @click="getAccount(item)">
                    {{ item.brandName }}
                  </td>
                  <td style="text-align: right">
                    <v-icon medium @click="deleteItem(item)"
                      >mdi-dots-vertical</v-icon
                    >
                  </td>
                </tr>
              </template>
              <template v-slot:no-data>
                <div v-if="showNoDataMessage">Не найденно подходящих записей.</div>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </template>
              <template v-slot:footer>
                <div class="v-data-footer">
                  <div class="v-data-footer__select">
                    Кол-во юр лиц на странице:
                    <v-select
                      class="custom-select"
                      v-model="itemsPerPage"
                      :items="footerProps.itemsPerPageOptions"
                      @change="changeItemsPerPage"
                      hide-details
                    ></v-select>
                  </div>
                  <div class="v-data-footer__pagination">
                    {{ (currentPage - 1) * itemsPerPage + 1 }} -
                    {{ Math.min(currentPage * itemsPerPage, totalItems) }} из
                    {{ totalItems }}
                  </div>
                  <div class="v-data-footer__icons-before">
                    <button
                      type="button"
                      :disabled="currentPage === 1"
                      class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                      :class="{ 'v-btn--disabled': currentPage === 1 }"
                      aria-label="Предыдущая страница"
                      @click="goToPreviousPage"
                    >
                      <span class="v-btn__content">
                        <i
                          aria-hidden="true"
                          class="v-icon notranslate mdi mdi-chevron-left theme--light"
                        ></i>
                      </span>
                    </button>
                  </div>
                  <div class="v-data-footer__icons-after">
                    <button
                      type="button"
                      :disabled="currentPage === totalPages"
                      class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                      :class="{ 'v-btn--disabled': currentPage === totalPages }"
                      aria-label="Следующая страница"
                      @click="goToNextPage"
                    >
                      <span class="v-btn__content">
                        <i
                          aria-hidden="true"
                          class="v-icon notranslate mdi mdi-chevron-right theme--light"
                        ></i>
                      </span>
                    </button>
                  </div>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
  </template>
  
  <script>
  import { mask } from "vue-the-mask";
  import { mapGetters } from "vuex";
  import { debounce } from 'lodash';
  export default {
    directives: { mask },
    name: "AccountList",
  
    data: () => ({
    isHovered: false,
    isHoveredTogo: false,
    showInputMenu: false,
    selectedOption: "",
    isPlaceholderHidden: false,
    isfullNameFocused: false,
    isBrandNameFocused: false,
    selectedRoles: null,
    fullName: "",
    brandName: "",
    dialogDelete: false,
    dialogCreate: false,
    search: "",
    snackbar: false,
    message: "",
    roleTranslations: {
      Owner: "Владелец",
      Administrator: "Администратор",
    },
    newAcc: {
    },
    account: {
    },
    selectedType: null,
    rolesTest: [
      { label: "CustomerApp", value: 1 },
      { label: "RestaurantApp", value: 2 },
      { label: "WebApp", value: 3 },
    ],
    headers: [
      {
        text: "Имя",
        align: "start",
        value: "name",
      },
      { text: "Бренд", value: "brandName" },
      { text: "Действия", align: "end", value: "actions", sortable: false },
    ],
    accounts: [],
    profiles: [],
    error: null,
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 0,
    isEditing: false,
    currentAcc: "",
    }),
  
    created() {
      this.initialize();
      // this.profileInfo();
    },
    computed: {
    filteredAccounts() {
      // Фильтруем данные внутри таблицы в зависимости от выбранного типа
      if (this.selectedType) {
        console.log(this.selectedType);
        return this.staffs.items
          .filter
          // (item) => item.rolesTest === this.selectedType
          ();
      } else {
        return this.accounts.items;
      }
    },
    footerProps() {
      return {
        itemsPerPageOptions: [3, 5, 7, 10], // Опции количества элементов на странице
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        totalItems: this.totalItems,
      };
    },
    ...mapGetters({
      user: "user",
    }),
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },
  },

  mounted() {
    window.addEventListener("click", this.handleClickOutside);
    window.addEventListener("click", this.handleClickOutsideTogo);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleClickOutside);
    window.removeEventListener("click", this.handleClickOutsideTogo);
  },

    methods: {
      getRoleTranslation(role) {
      return this.roleTranslations[role] || role;
    },
    getEnglishRoleTranslation(role) {
      for (const key in this.roleTranslations) {
        if (Object.prototype.hasOwnProperty.call(this.roleTranslations, key)) {
          if (this.roleTranslations[key] === role) {
            return key;
          }
        }
      }
      return role;
    },
    hidePlaceholder(inputName) {
      if (inputName === "fullName" && inputName === "brandName") {
        this.isfullNameFocused = true;
        this.isBrandNameFocused = true;
      }
      // } else if (inputName === "actualAdress") {
      //   this.isActualAdressFocused = true;
      // }
    },

    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      // this.activeTextarea = null;
    },

    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    closeDialog() {
      this.dialogCreate = false;
      this.fullName = "";
        this.brandName = "";
        this.isEditing = false;
    },
    handleButtonClick() {
      if (this.isEditing) {
        // Выполните метод для редактирования
        this.editAccount();
      } else {
        // Выполните метод для создания нового приложения
        this.createNewAccount();
      }
    },
      initialize() {
        this.$http
          .put("/api/Account/PagedList", {
            pageNumber: this.currentPage,
            pageSize: this.itemsPerPage,
            headers: {
                      'Content-Type': 'application/json',
                    },
                    filters: [
          {
            property: "Name",
            operator: "contains",
            value: this.search,
          }
        ],
          })
          .then((response) => {
            this.accounts = response.data;
            this.totalPages = response.data.totalPages; // Общее количество страниц
          this.totalItems = response.data.totalCount;
          if (this.totalItems === 0) {
            this.showNoDataMessage = true;
          } else {
            this.showNoDataMessage = false;
          }
          })
          .catch((error) => (this.error = error));
      },
    goToNextPage() {
      if (this.currentPage < this.totalItems) {
        this.currentPage++;
        this.initialize();
      }
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.initialize();
      }
    },
    changeItemsPerPage() {
      this.currentPage = 1; // Сбрасываем текущую страницу при изменении количества элементов на странице
      this.initialize(); // Вызов метода для загрузки данных с новым количеством элементов на странице
    },
  
      deleteItem(item) {
        this.editedIndex = this.accounts.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialogDelete = true;
      },
  
      deleteItemConfirm() {
        this.$http
          .delete(`/api/Account/${this.editedItem.id}`)
          .then(() => {
            this.accounts.items.splice(this.editedIndex, 1);
            this.initialize();
            this.closeDelete();
            this.snackbar = true;
            this.message = "Юр лицо успешно удалено";
          })
          .catch((error) => {
            alert(error);
            this.snackbar = true;
            this.message = "Ошибка удаления юр лица";
          });
      },
  
      closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
        });
      },
  
      createItem() {
        this.dialogCreate = true;  
        this.fullName = "";
        this.brandName = "";
        this.isEditing = false;
      },
  
      closeCreate() {
        this.dialogCreate = false;
        this.fullName = "";
        this.brandName = "";
        this.isEditing = false;
      },
  
      createNewAccount() {
        this.newAcc.name = this.fullName;
        this.newAcc.brandName = this.brandName;
        this.$http
          .post("/api/Account", this.newAcc)
          .then(() => {
            this.initialize();
            this.dialogCreate = false;
            this.newAcc = {
              name: "",
              brandName: "",
            };
            this.snackbar = true;
          this.message = "Юр лицо успешно добавлено"
          })
          .catch((error) => {
            this.error = error;
            this.snackbar = true;
          this.message = "Ошибка добавления Юр лица"
          });
      },
      getAccount(item) {
        this.createItem();
        this.isEditing = true;
        this.editedIndex = this.accounts.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.currentAcc = item.id;
        this.$http
          .get(
            `/api/Account/${item.id}`
          )
          .then((response) => {
            this.account = response.data;
            this.fullName = this.account.name;
            this.brandName = this.account.brandName;
          })
          .catch((error) => {
            alert(error);
          });
      },
      editAccount(){
      this.newAcc = {}
      this.newAcc.name = this.fullName;
      this.newAcc.brandName = this.brandName;
      this.$http
            .put(`/api/Account/${this.currentAcc}`, this.newAcc, {
            headers: {
                'Content-Type': 'application/json',
            },
            })
            .then((response) => {
              this.initialize();
              this.dialogCreate = false;
              this.employee = response.data;
              this.newEmp = {
              name: "",
              brandName: "",
              };
              this.snackbar = true;
              this.message = "Юр лицо успешно измененно";
            })
            .catch((error) => {
            alert(error);
            this.snackbar = true;
            this.message = "Ошибка редактирования юр лица";
        });
    },
      editItem(item) {
        this.$router.push({ path: `/admin/accounts/${item.id}` });
      },
      toggleInputMenu() {
      this.isHovered = false;
      this.showInputMenu = !this.showInputMenu;
    },

    selectOption(option) {
      this.selectedOption = option;
      this.toggleInputMenu(); // Вызовите метод для скрытия меню (если это требуется)
    },

    handleClickOutside(event) {
      const inputButton = this.$refs.inputButton;
      const inputMenu = this.$refs.inputMenu;

      if (
        inputButton &&
        inputMenu &&
        !inputButton.contains(event.target) &&
        !inputMenu.contains(event.target)
      ) {
        this.showInputMenu = false;
      }
    },

    addBorderClass() {
      this.isHovered = true;
    },
    removeBorderClass() {
      this.isHovered = false;
    },
    },
    watch: {
    fullName() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("fullNameTextarea");
      });
    },
    search: debounce(function() {
    // Выполняем запрос с задержкой после изменения переменной поиска
    this.initialize();
  }, 500),

    branName() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("brandNameTextarea");
      });
    },
  },
  };
  </script>

  <style scoped>

  /* Custom styles for the "Закрыть" button */
  .custom-snackbar .v-btn--text.v-btn--success {
    color: #fff; /* White text color */
  }
  
  .custom-snackbar .v-btn--text.v-btn--success:hover {
    background-color: #357a38; /* Darker green background on hover */
  }
  .role-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .border-hover {
    border-radius: 8px;
    border: 1px solid var(--03, #c1c1c1) !important;
    /* Другие стили по вашему усмотрению */
  }
  .v-col.cols-7 .border-hover {
    border-radius: 8px;
    border: 1px solid var(--03, #c1c1c1) !important;
    background: var(--white, #fff);
  }
  .profile-menu-item:hover {
    /* Здесь вы можете задать стили для hover-эффекта */
    background-color: #f0f0f0; /* Пример: изменение цвета фона при наведении */
    color: #333; /* Пример: изменение цвета текста при наведении */
    cursor: pointer; /* Пример: изменение курсора при наведении */
    /* Другие стили по вашему усмотрению */
  }
  .border-input {
    border-radius: 8px;
    border: 1px solid var(--03, #c1c1c1) !important;
    background: var(--white, #fff);
    padding: 6px;
  }
  .border-white {
    border-radius: 8px;
    border: 1px solid var(--02, white);
    background: var(--white, #fff);
    padding: 6px;
    cursor: pointer;
    width: 100%;
  }
  .divider-modal {
    background: #e0e0e0;
    height: 1px;
    width: 100%; /* Расширяем на всю ширину родительского контейнера */
  }
  
  .profile-menu-item {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    padding-left: 30px;
    padding-top: 11px;
    padding-bottom: 11px;
    width: 100%;
  }
  
  .input-menu {
    position: absolute;
    bottom: 32%;
    left: 30.7%;
    width: 347px;
    height: auto;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 30px 0px rgba(200, 200, 200, 0.52);
    transition: bottom 0.3s;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 100;
    /* right: -15px; */
  }
  .select-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    /* margin-left: 15px; */
  }
  .option-btn {
    margin-bottom: 0px;
    color: var(--Gray-1, #333);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    margin-left: 5px;
  }
  ::v-deep .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
  }
  .btn-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 46px;
    align-items: center;
    margin-right: 20px;
  }
  .red-dot-margin-2 {
    margin-left: -17px;
  }
  .red-dot-margin-1 {
    text-align: center;
    margin-left: -17px;
  }
  .btn-contaiener {
    display: flex;
    justify-content: space-evenly;
  }
  .cancel-btn {
    color: var(--02, #848484);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    border: 1px solid var(--02, #848484);
    display: inline-flex;
    padding: 13px 29px;
    justify-content: center;
    align-items: center;
    gap: 32.5px;
    cursor: pointer;
    margin-right: 21px;
  }
  .delete-btn {
    color: var(--Red, #eb5757);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }
  .snackbar-btn {
    color: var(--Red, #eb5757);
    font-family: Inter;
    font-size: 0.875rem;
      font-weight: 400;
      letter-spacing: 0.0178571429em;
      line-height: 1.25rem;
      margin-right: auto;
      padding: 14px 16px;
      text-align: initial;
    cursor: pointer;
  
  }
  .save-btn {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background: var(--logo, #00b800);
    display: inline-flex;
    padding: 13px 29px;
    justify-content: center;
    align-items: center;
    gap: 32.5px;
    cursor: pointer;
  }
  .del-btn {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background: var(--logo, #b80900);
    display: inline-flex;
    padding: 13px 29px;
    justify-content: center;
    align-items: center;
    gap: 32.5px;
    cursor: pointer;
    margin-left: 30px;
  }
  .text-limit {
    width: 30ch; /* Отображение примерно 10 символов */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--Gray-1, #333);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    margin-bottom: 0px;
  }
  .horizontal-line {
    /* Ширина div'а будет равна 100% от родительского контейнера */
    height: 1px; /* Толщина линии */
    background: #848484; /* Цвет линии (в данном случае черный) */
  }
  .custom-select {
    position: relative;
    width: 350px;
    cursor: pointer;
    /* margin-left: 9px; */
    color: var(--gray-1, #333);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
  }
  
  .select-arrow {
    position: absolute;
    top: 40%;
    right: 30px;
    /* transform: translateY(-50%); */
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333; /* Цвет стрелочки */
  }
  .col-description,
  :focus {
    outline: none; /* Убираем внешний контур (outline) при фокусе */
    border: none; /* Убираем границу (border) при фокусе */
    box-shadow: none; /* Убираем тень (box-shadow) при фокусе */
  }
  .red-dot {
    color: #e41313;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .col-title {
    color: var(--gray-1, #333);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-wrap: break-word;
    margin-bottom: 0px;
  }
  .col-paddings-r-l {
    padding-right: 0px;
    padding-left: 0px;
  }
  
  .col-content {
    display: flex;
    align-items: flex-start;
  }
  .role-margin {
    margin-right: 37px;
  }
  .role-margin-1 {
    margin-right: 30px;
  }
  
  .col-content-2 {
    display: flex;
    align-items: center;
    width: fit-content;
  }
  .col-description {
    color: var(--gray-1, #333);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    margin-bottom: 0px;
    word-wrap: break-word;
    resize: none;
    height: 30px;
    /* min-height: 20px; */
    width: 100%;
    /* margin-left: 18px; */
  }
  
  ::v-deep .v-dialog {
    border-radius: 20px;
    background: #fff;
    margin: 24px;
    overflow-y: auto;
    pointer-events: auto;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 100%;
    z-index: inherit;
    box-shadow: none;
  }
  
  .modal-title {
    color: var(--gray-1, #333);
    font-family: Inter !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
  }
  .page-title {
    color: var(--gray-1, #333);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .vertical-line {
    width: 1px;
    height: 42px;
    background: #e0e0e0;
    margin-left: 31px;
  }
  .vertical-line-1 {
    width: 1px;
    height: 50px;
    background: #e0e0e0;
  }
  .add-btn .add-text {
    color: var(--01, #333);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    margin-left: 12px;
    transition: color 0.3s ease;
  }
  .add-btn:hover .add-text {
    /* Цвет текста при наведении */
    color: black; /* Замените "red" на желаемый цвет текста */
    font-weight: 500;
    font-size: 17px;
  }
  .add-btn {
    display: flex;
    align-items: center;
    padding: 12px 0px;
    margin-left: 37px;
    cursor: pointer;
  }
  .top-panel {
    border-bottom: 1px solid var(--04, #eaeaea);
    background: var(--white, #fff);
    height: 50px;
    display: flex;
    align-items: center;
  }
  .search-container {
    padding-left: 40px;
    padding-right: 30px;
    padding-top: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .img-pluss-btn {
    margin-left: 10px;
  }
  .arrow-btn {
    /* border-right: 1px solid var(--04, #eaeaea); */
    width: 56px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .search-notfound {
    /* display: flex; */
    margin: 0 auto;
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
  }
  .search-component {
    max-width: 606px;
    width: 100%;
    height: 45px;
    margin: 0 auto;
    display: flex;
    position: relative;
    flex-direction: row;
  }
  .search-component__input {
    border-radius: 100px;
    border: 1px solid var(--gray-5, #e0e0e0);
    width: 100%;
    outline: unset;
    padding: 0 40px 0 15px;
    display: flex;
    align-items: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
  
  .search-component__icon {
    position: absolute;
    top: 10.5px;
    right: 55px;
  }
  #staff-add-button {
    border-radius: 8px;
    background-color: #333;
    color: var(--white, #fff);
    gap: 12px;
    padding: 10px 56px;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    height: 45px;
  }
  ::v-deep tbody tr:nth-child(2n) {
    background: rgba(242, 242, 246, 0.5);
  }
  ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    color: var(--gray-1, #333) !important;
    font-family: Inter;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  ::v-deep .v-data-table__wrapper > table > thead > tr > th {
    color: var(--gray-1, #333) !important;
    font-family: Inter;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  ::v-deep
    .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th {
    border-bottom: thin solid #848484;
  }
  </style>
  